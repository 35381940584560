<template>
  <div>
    <v-container>
      <RequestDocument />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    RequestDocument: () => import("@/components/requestDocument"),
  },
};
</script>
